<template>
<div>
  <div class="cf">
    梧州市群艺馆文化云终端
  </div>
  <md-button @click="sendOk"  type="primary">确定提交信息</md-button>
</div>
</template>

<script>
export default {
name: "cfwx",
methods:{
  async sendOk(){
    const res = await  this.$api.post('/sendWxloginOk',this.$store.state.userInfo)
    const { success} = res.data;
    if(success)  this.$toast.succeed("登录成功")

  }
}
}
</script>

<style scoped>

.cf{
  text-align: center;
  line-height: 120px;
}
</style>
